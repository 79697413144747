import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage } from 'react-intl';

export type OrderCompleteProps = {
  onClose: () => void;
};

export const OrderComplete = ({ onClose }: OrderCompleteProps) => {
  return (
    <div className="display-flex flex-column">
      <div className="border-color-gray mb-5 rounded border bg-white p-10">
        <div className={'flex-column flex gap-3 text-center'}>
          <span className="rioglyph rioglyph-ok-sign text-color-primary text-[90px]" />
          <span className="text-size-h1 text-color-dark">
            <FormattedMessage id="general.newOrder.congratulations.title" />
          </span>
          <span className="text-color-dark">
            <FormattedMessage id="general.newOrder.congratulations.description" />
          </span>
        </div>
        <div className="margin-top-20 btn-toolbar justify-center">
          <Button className={'width-120'} onClick={onClose} bsStyle={'primary'}>
            <span className={'padding-right-5'}>
              <FormattedMessage id="general.button.goToChargingCards" />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
