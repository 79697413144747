import type { OrderCardsParams } from '@/api/cardOrders/types';

export const buildCardOrderRequest = (params: OrderCardsParams) => ({
  letterLanguage: params.countryLocale,
  shippingAddress: {
    firstAddressLine: params.shipping?.address ?? '',
    secondAddressLine: params.shipping?.address2,
    zip: params.shipping?.postCode ?? '',
    city: params.shipping?.city ?? '',
    countryCode: params.shipping?.country ?? '',
  },
  cardDetails:
    params.chargingCards?.cardsListCustomization?.map((c) => ({
      firstRow: c.line1,
      secondRow: c.line2,
      productId: 95379,
      supportLabel: params.supportLabel,
      supportPhone: params.supportPhone,
    })) ?? [],
});
