import type { ComponentProps, InputHTMLAttributes } from 'react';

import FormGroup from '@/components/ui/FormGroup';

export type InputProps = {
  inputAttributes?: InputHTMLAttributes<HTMLInputElement>;
} & ComponentProps<typeof FormGroup>;

const Input = ({ label, id = `${label}-input`, inputAttributes, ...formGroupProps }: InputProps) => {
  return (
    <FormGroup {...formGroupProps} label={label}>
      <input {...inputAttributes} id={id} className="form-control" type="text" />
    </FormGroup>
  );
};

export default Input;
