import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage } from 'react-intl';

import type { RfidChargingCardsSchemaType } from '@/components/RfidOrderModal/ChargingCardsStep';
import type { ShippingSchemaType } from '@/components/RfidOrderModal/ShippingAddressStep';

import { addressBuilder, checkCustomization, t } from '@/lib/utils';

export type ReviewStepProps = {
  chargingCardsState: RfidChargingCardsSchemaType;
  shippingAddress?: ShippingSchemaType;
  goToPage: (stepNumber: number) => void;
  setCallback: () => void;
  onClickPrevious: () => void;
  isLoading: boolean;
};

export const ReviewStep = ({
  chargingCardsState,
  shippingAddress,
  goToPage,
  setCallback,
  onClickPrevious,
  isLoading,
}: ReviewStepProps) => {
  const { cardsListCustomization } = chargingCardsState;

  const finalAddress = addressBuilder(
    `${shippingAddress?.address ?? ''}  ${shippingAddress?.address2 ?? ''}`,
    `${shippingAddress?.postCode ?? ''} ${shippingAddress?.city ?? ''}`,
  );

  return (
    <div className="display-flex flex-column">
      <div className="border-color-gray mb-5 rounded border bg-white p-10">
        <div className="display-flex flex-row justify-between">
          <div className="text-bold text-size-18 pb-5">
            <FormattedMessage id="general.chargingCards" />
          </div>
          <Button onClick={() => goToPage(0)} bsStyle={'muted'} iconOnly data-testid="edit-button">
            <span className="rioglyph rioglyph-pencil text-color-primary text-size-20 margin-right-10" />
          </Button>
        </div>
        <div className="display-flex flex-row gap-20">
          <div className="flex-basis-80pct text-size-14">
            <div className="pb-5">
              <FormattedMessage id="onboarding.numberOfChargingCards" />:{' '}
              <strong>{chargingCardsState.cardsNumber}</strong>
            </div>
            <div className="pb-5">
              <FormattedMessage id="onboarding.chargingCardsWillBeSentTo" />: <strong>{finalAddress}</strong>
            </div>
            <div className={`${checkCustomization(cardsListCustomization) ? 'pb-5' : ''}`}>
              <FormattedMessage id="onboarding.chargingCardCustomisation" />{' '}
              <strong>
                {checkCustomization(cardsListCustomization) ? (
                  <FormattedMessage id="general.yes" />
                ) : (
                  <FormattedMessage id="general.no" />
                )}
              </strong>
            </div>
            {checkCustomization(cardsListCustomization) &&
              cardsListCustomization?.map((customizedCard, index) => {
                if (
                  (customizedCard.line1 && customizedCard.line1 !== '') ||
                  (customizedCard.line2 && customizedCard.line2 !== '')
                ) {
                  return (
                    <div key={`${index.toString()}-custom-review`} className="pb-5">
                      <FormattedMessage id="onboarding.customisationCard" /> {index + 1}:{' '}
                      <strong>{`${customizedCard.line1 ?? 'N.D'} / ${customizedCard.line2 ?? 'N.D'}`}</strong>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div className="btn-toolbar xs:justify-center md:justify-between">
        <Button className={'width-120'} onClick={onClickPrevious}>
          <span className="rioglyph rioglyph-chevron-left text-bold text-size-14 padding-right-5" />
          <span>{t('onboarding.button.back')}</span>
        </Button>
        <Button
          className={`width-120 ${isLoading ? 'btn-loading' : ''}`}
          onClick={setCallback}
          bsStyle={'primary'}
          disabled={isLoading}>
          <span className={'padding-right-5'}>{t('chargingCards.newOrder.confirm')}</span>
          <span className="rioglyph rioglyph-chevron-right text-bold text-size-14" />
        </Button>
      </div>
    </div>
  );
};
