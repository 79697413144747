import { SteppedProgressBar } from '@rio-cloud/rio-uikit';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { ADMINISTRATION_CARDS } from '@/routes/Router';

import { useUserStore } from '@/stores/userStore';

import { useCustomerContract } from '@/api/account';
import { useOrderCards } from '@/api/cardOrders';

import { ChargingCardsStep, type RfidChargingCardsSchemaType } from '@/components/RfidOrderModal/ChargingCardsStep';
import { OrderComplete } from '@/components/RfidOrderModal/OrderComplete';
import { OrderError } from '@/components/RfidOrderModal/OrderError';
import { ReviewStep } from '@/components/RfidOrderModal/ReviewStep';
import { ShippingAddressStep, type ShippingSchemaType } from '@/components/RfidOrderModal/ShippingAddressStep';
import type { StepConfig, StepperProps } from '@/components/RfidOrderModal/types';
import { useStepperNavigation } from '@/hooks/useStepperNavigation';
import { t } from '@/lib/utils';
import { trackOrderAbandon, trackOrderComplete, trackOrderError, trackOrderStep } from './utils/analytics';

const STEPS: StepConfig[] = [
  {
    name: 'charging_cards',
    label: 'general.chargingCards',
    component: ChargingCardsStep,
  },
  {
    name: 'shipping_address',
    label: 'general.shippingAddress',
    component: ShippingAddressStep,
  },
  {
    name: 'review',
    label: 'onboarding.stepper.summary',
    component: ReviewStep,
  },
];

export const Stepper = ({ setDisplayDialog, source, onStepChange }: StepperProps) => {
  const navigate = useNavigate();
  const { countryLocale, countryIntl } = useUserStore.getState();
  const { data: contract } = useCustomerContract();
  const orderCardsMutation = useOrderCards();

  const [currentStep, setCurrentStep] = useState(0);
  const [disableFollowingPages, setDisableFollowingPages] = useState<number | undefined>(undefined);
  const [orderStatus, setOrderStatus] = useState<'pending' | 'completed' | 'error'>('pending');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chargingCardsState, setChargingCardsState] = useState<RfidChargingCardsSchemaType>({
    cardsNumber: 1,
    cardsListCustomization: [{ line1: '', line2: '' }],
  });
  const [shippingAddress, setShippingAddress] = useState<ShippingSchemaType>();

  const { handleNext, handlePrevious, handleStepSelect } = useStepperNavigation(
    setCurrentStep,
    setDisableFollowingPages,
  );

  const trackStep = useCallback((step: number) => {
    trackOrderStep(step + 1, STEPS[step].name, 'view', source);
  }, []);

  const handleChargingCardState = useCallback(
    (state: RfidChargingCardsSchemaType) => {
      setDisableFollowingPages(currentStep);
      setChargingCardsState(state);
      setDisableFollowingPages(1);
    },
    [currentStep],
  );

  const handleShippingAddress = useCallback(
    (state: ShippingSchemaType) => {
      setDisableFollowingPages(currentStep);
      setShippingAddress(state);
      setDisableFollowingPages(2);
    },
    [currentStep],
  );

  const handleOrder = useCallback(async () => {
    try {
      await orderCardsMutation.mutateAsync({
        chargingCards: chargingCardsState,
        shipping: shippingAddress,
        countryLocale,
        supportLabel: t('cardDetails.supportLabel', {}, countryIntl),
        supportPhone: t('cardDetails.supportPhone', {}, countryIntl),
      });

      trackOrderComplete(
        chargingCardsState.cardsNumber,
        chargingCardsState.cardsListCustomization.some((card) => card.line1 || card.line2),
      );
      setOrderStatus('completed');
    } catch {
      trackOrderError(currentStep, 'api_failure');
      setOrderStatus('error');
    }
  }, [orderCardsMutation, chargingCardsState, shippingAddress, countryLocale, countryIntl, currentStep]);

  const handleClose = useCallback(() => {
    setDisplayDialog?.({
      currentStep,
      stepName: STEPS[currentStep].name,
      orderStatus,
    });
    navigate(ADMINISTRATION_CARDS);
  }, [navigate, setDisplayDialog]);

  const handleTryAgain = useCallback(() => {
    setOrderStatus('pending');
    setCurrentStep(STEPS.length - 1);
  }, []);

  useEffect(() => {
    const handleEscapeKey = () => {
      if (orderStatus === 'pending') {
        trackOrderAbandon(currentStep, STEPS[currentStep].name, source);
      }
    };

    window.addEventListener('keydown', handleEscapeKey);
    return () => window.removeEventListener('keydown', handleEscapeKey);
  }, [currentStep, orderStatus, source]);

  useEffect(() => {
    if (orderStatus === 'pending') {
      trackStep(currentStep);
    }
  }, [currentStep, orderStatus, trackStep]);

  useEffect(() => {
    onStepChange({
      currentStep,
      stepName: STEPS[currentStep].name,
      orderStatus,
    });
  }, [currentStep, orderStatus, onStepChange]);

  useEffect(() => {
    if (contract && !shippingAddress) {
      setShippingAddress({
        address: contract.firstAddressLine ?? '',
        address2: contract.secondAddressLine ?? '',
        country: contract.countryCode ?? '',
        postCode: contract.zip ?? '',
        city: contract.city ?? '',
      });
    }
  }, [contract, shippingAddress]);

  const getStepIcon = useCallback(
    (step: number) =>
      step < currentStep ? (
        <i className="rioglyph rioglyph-ok" />
      ) : (
        <div className="padding-right-1 padding-top-1">{step + 1}</div>
      ),
    [currentStep],
  );

  const renderCurrentStep = () => {
    const StepComponent = STEPS[currentStep].component;
    const props = {
      state: currentStep === 0 ? chargingCardsState : shippingAddress,
      setState: currentStep === 0 ? handleChargingCardState : handleShippingAddress,
      handleNext,
      handleBack: currentStep > 0 ? handlePrevious : undefined,
      ...(currentStep === 2 && {
        chargingCardsState,
        shippingAddress,
        setCallback: handleOrder,
        onClickPrevious: handlePrevious,
        goToPage: handleStepSelect,
        isLoading,
      }),
    };

    return <StepComponent {...props} />;
  };

  if (orderStatus === 'error') {
    return <OrderError tryAgain={handleTryAgain} />;
  }

  if (orderStatus === 'completed') {
    return <OrderComplete onClose={handleClose} />;
  }

  return (
    <div className="margin-50">
      <div className="margin-bottom-25 padding-bottom-10 margin-top-50 margin-x-20">
        <SteppedProgressBar
          variant="circle"
          selectedStepNumber={currentStep}
          onSelectedChanged={handleStepSelect}
          disableFollowingPages={disableFollowingPages ?? currentStep}
          mobileBreakpoint={false}
          labels={STEPS.map((step, index) => ({
            icon: getStepIcon(index),
            label: <FormattedMessage id={step.label} />,
          }))}
        />
      </div>
      {renderCurrentStep()}
    </div>
  );
};
