import { CHARGING_CARDS_CHARACTER_LIMIT } from '@/lib/constants';
import { cn } from '@/lib/utils';

const DEFAULT_CARD_NUMBER = 12345;

interface CardLineProps {
  content?: string;
  defaultText: string;
}

interface CardPreviewProps {
  line1?: string;
  line2?: string;
  number?: number;
}

export const CardPreview = ({ line1 = '', line2 = '', number = DEFAULT_CARD_NUMBER }: CardPreviewProps) => {
  const CardLine = ({ content, defaultText }: CardLineProps) => {
    const isContentEmpty = !content || content.trim() === '';
    const displayText = isContentEmpty ? defaultText : content.substring(0, CHARGING_CARDS_CHARACTER_LIMIT);

    return <span className={cn(isContentEmpty ? 'text-gray-500' : 'text-white')}>{displayText}</span>;
  };

  return (
    <div
      className="flex gap-5 bg-cover bg-[url('/cardPreview.svg')] w-[238px] h-[150px] drop-shadow-md"
      role="img"
      aria-label="Card Preview">
      <div className="flex mt-[45px] flex-col w-[210px] ml-5 text-md overflow-hidden">
        <CardLine content={line1} defaultText="Line 1" />
        <CardLine content={line2} defaultText="Line 2" />
        <span role="number" className="flex justify-end text-white mt-14">
          {number}
        </span>
      </div>
    </div>
  );
};
