import type { Dispatch, SetStateAction } from 'react';

export const useStepperNavigation = (
  setSelectedStep: Dispatch<SetStateAction<number>>,
  setDisableFollowingPages: Dispatch<SetStateAction<number | undefined>>,
) => {
  const handleNext = () => {
    setSelectedStep((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setSelectedStep((prev) => {
      const newStep = Math.max(0, prev - 1);
      setDisableFollowingPages(newStep);
      return newStep;
    });
  };

  const handleStepSelect = (step: number) => {
    const validStep = Math.max(0, step);
    setSelectedStep(validStep);
    setDisableFollowingPages(validStep);
  };

  return {
    handleNext,
    handlePrevious,
    handleStepSelect,
  };
};
