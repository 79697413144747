export const TERMS_AND_CONDITIONS_URL: string = 'https://rio.cloud/de/documents/man-charge-and-go/tos';

export const PRIVACY_POLICE_URL: string = 'https://rio.cloud/de/documents/man-charge-and-go/privacy-policy';

export const PRICE_LIST_URL: string = '/price-list.pdf';

export const CHARGING_CARDS_CHARACTER_LIMIT: number = 25;

export const MIN_CARDS_REQUEST_LIMIT: number = 1;

export const MAX_CARDS_REQUEST_LIMIT: number = 40;
