import { Button, Collapse } from '@rio-cloud/rio-uikit';
import { type Control, Controller, type FieldArrayWithId, type FieldErrors, type UseFormWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { ChargingCardsSchemaType } from '@/features/onboarding/components/ChargingCardsStep';

import { CardPreview } from '@/components/CardPreview';
import Input from '@/components/ui/Input';

import { CHARGING_CARDS_CHARACTER_LIMIT } from '@/lib/constants';
import { cn, t } from '@/lib/utils';

export type ChargingCardsCustomizationProps = {
  fields: FieldArrayWithId<ChargingCardsSchemaType, 'cardsListCustomization'>[];
  errors?: FieldErrors<ChargingCardsSchemaType>;
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
  control: Control<ChargingCardsSchemaType>;
  watch: UseFormWatch<ChargingCardsSchemaType>;
};

export const ChargingCardsCustomization = ({
  fields,
  errors,
  isOpen = false,
  setOpen,
  control,
  watch,
}: ChargingCardsCustomizationProps) => {
  const INPUT_RULES = { maxLength: CHARGING_CARDS_CHARACTER_LIMIT };

  const handleToggleOpen = () => setOpen(!isOpen);

  const renderCharacterCount = (value: string | undefined, isError: boolean) => (
    <span className={cn(isError ? 'text-red-500' : 'text-color-dark', 'text-[12px] py-[2px] flex self-end')}>
      {value?.length || 0} / {CHARGING_CARDS_CHARACTER_LIMIT}
    </span>
  );

  const renderLineInput = (index: number, lineNumber: number, field: any, fieldState: any, errorMessage?: string) => (
    <div className="flex flex-col">
      <Input
        error={errorMessage}
        inputAttributes={field}
        className={cn(fieldState.invalid ? 'has-error' : 'grow margin-bottom-0', '!mb-0 !min-w-min')}
        label={`${t('onboarding.printedLine')} ${lineNumber}`}
      />
      {renderCharacterCount(field.value, fieldState.invalid)}
    </div>
  );

  const renderCardInputs = (cardIndex: number) => {
    const watchValues = watch('cardsListCustomization')[cardIndex];

    return (
      <div key={`${cardIndex}-line`} className="w-full">
        <div className="text-bold mb-4">{`${t('onboarding.card')} ${cardIndex + 1}`}</div>
        <div className="flex flex-row gap-20">
          <div className="flex flex-col w-full">
            <Controller
              name={`cardsListCustomization.${cardIndex}.line1`}
              control={control}
              rules={INPUT_RULES}
              render={({ field, fieldState }) =>
                renderLineInput(
                  cardIndex,
                  1,
                  field,
                  fieldState,
                  errors?.cardsListCustomization?.[cardIndex]?.line1?.message,
                )
              }
            />
            <Controller
              name={`cardsListCustomization.${cardIndex}.line2`}
              control={control}
              rules={INPUT_RULES}
              render={({ field, fieldState }) =>
                renderLineInput(
                  cardIndex,
                  2,
                  field,
                  fieldState,
                  errors?.cardsListCustomization?.[cardIndex]?.line2?.message,
                )
              }
            />
          </div>
          <hr className="w-px h-[150px] bg-gray m-0" />
          <div className="self-center">
            <CardPreview line1={watchValues.line1} line2={watchValues.line2} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="border-color-gray rounded border p-5">
      <div className="display-flex flex-row">
        <div className="flex-basis-80pct">
          <h2 className="text-bold text-size-18 pb-10">
            <FormattedMessage id="onboarding.customiseChargingCards.title" />
          </h2>
          <p className="pb-5">
            <FormattedMessage id="onboarding.customiseChargingCards.description" />
          </p>
        </div>
        <div className="display-flex flex-basis-20pct align-items-center justify-end">
          <Button onClick={handleToggleOpen} bsStyle="primary" variant="link">
            <span className="text-decoration-underline">
              <FormattedMessage id={isOpen ? 'onboarding.button.close' : 'onboarding.button.customiseCards'} />
            </span>
          </Button>
        </div>
      </div>

      <Collapse open={isOpen}>
        <div className="bg-lighter rounded p-5">
          <p className="flex items-center self-center">
            <i className="rioglyph rioglyph-info-sign mr-2" />
            <FormattedMessage id="onboarding.warning.previewCards.text" />
          </p>
          <div className={cn('grid grid-cols-2 gap-20 h-full', !isOpen && 'hidden')}>
            {fields?.map((_, index) => renderCardInputs(index))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};
