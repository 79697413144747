import type {
  CardOrderDetailsType,
  CompanyDetailsType,
  ContactDetailsType,
  ContractUpdateType,
  OnboardingRequestType,
  ShippingAddressType,
} from '@/api/schema';

import type { ContactDetailsSchemaType } from '@/features/administration/contract/validations/contact';
import type { InvoiceDetailsSchemaType } from '@/features/administration/contract/validations/invoice';
import type { ChargingCardsSchemaType } from '@/features/onboarding/components/ChargingCardsStep';
import type { InvoiceAddressSchemaType } from '@/features/onboarding/components/InvoicingAddressStep';
import type { VatSchemaSchemaType } from '@/features/onboarding/components/VatStep';

import { buildPhoneNumber } from '@/lib/utils';

const buildShippingAddress = (invoiceState: InvoiceAddressSchemaType): ShippingAddressType => {
  if (invoiceState.differentShippingAddress) {
    return {
      firstAddressLine: invoiceState.shippingAddress?.address ?? '',
      secondAddressLine: invoiceState.shippingAddress?.address2,
      zip: invoiceState.shippingAddress?.postCode ?? '',
      city: invoiceState.shippingAddress?.city ?? '',
      countryCode: invoiceState.shippingAddress?.country ?? '',
    };
  }
  return {
    firstAddressLine: invoiceState.invoice?.address ?? '',
    secondAddressLine: invoiceState.invoice?.address2,
    zip: invoiceState.invoice?.postCode ?? '',
    city: invoiceState.invoice?.city ?? '',
    countryCode: invoiceState.invoice?.country ?? '',
  };
};

export const buildOnboardingRequest = (
  invoiceState: InvoiceAddressSchemaType,
  cardsState: ChargingCardsSchemaType,
  vatState: VatSchemaSchemaType,
  countryLocale: string,
  supportLabel: string,
  supportPhone: string,
): OnboardingRequestType => {
  const companyDetails: CompanyDetailsType = {
    organizationNumber: invoiceState.invoice.companyRegistrationNumber,
    vatList: vatState.additional.map((v) => ({
      vatNumber: v.vat,
      countryCode: v.country,
      permanentEstablishment: v.permanentEstablishment === 'true',
    })),
    invoiceEmailAddress: invoiceState.invoice.invoiceEmailAddress,
  };

  const contactDetails: ContactDetailsType = {
    contactPhoneNumber: buildPhoneNumber(invoiceState.invoice.countryCode, invoiceState.invoice.phoneNumber),
    contactEmail: invoiceState.invoice.personalEmailAddress,
  };

  const cardOrderDetails: CardOrderDetailsType = {
    letterLanguage: countryLocale,
    shippingAddress: buildShippingAddress(invoiceState),
    cardDetails: cardsState.cardsListCustomization.map((c) => ({
      firstRow: c.line1,
      secondRow: c.line2,
      alias: '',
      productId: 95379,
      supportLabel,
      supportPhone,
    })),
  };

  return {
    companyDetails,
    contactDetails,
    cardOrderDetails,
  };
};

const mapVatList = (vatList: InvoiceDetailsSchemaType['vatList']): ContractUpdateType['vatList'] =>
  vatList.map(({ vat, country, permanentEstablishment }) => ({
    vatNumber: vat,
    countryCode: country,
    permanentEstablishment: permanentEstablishment === 'true',
  }));

export const buildContractUpdate = (
  contact: ContactDetailsSchemaType,
  invoice: InvoiceDetailsSchemaType,
  organizationNumber?: string,
): ContractUpdateType => ({
  vatList: mapVatList(invoice.vatList),
  organizationNumber,
  invoiceEmailAddress: invoice.email,
  contactEmailAddress: contact.email,
  contactPhoneNumber: contact.rawPhone,
});
