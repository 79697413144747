import { useQueryClient } from '@tanstack/react-query';

import { useAccountStore } from '@/stores/accountStore';

import type { CreateCustomerParams, UpdateContractParams } from '@/api/account/types';
import { buildContractUpdate, buildOnboardingRequest } from '@/api/account/utils';
import { ContractUpdate, CustomerContract, type CustomerContractType, OnboardingRequest } from '@/api/schema';

import { useApiMutation } from '@/hooks/useApiMutation';
import { useApiQuery } from '@/hooks/useApiQuery';

import { axiosInstance } from '@/lib/api/axios';
import { logError } from '@/lib/logger';
import { useUserStore } from '@/stores/userStore';

// POST: /account - Onboards customer that fulfilled the form into Charge&Go
export const createCustomer = async (params: CreateCustomerParams): Promise<void> => {
  const { canOnboard } = useUserStore.getState();

  if (!canOnboard) {
    return;
  }

  const request = OnboardingRequest.safeParse(
    buildOnboardingRequest(
      params.invoicingAddressState,
      params.chargingCardsState,
      params.vatState,
      params.countryLocale,
      params.supportLabel,
      params.supportPhone,
    ),
  );

  if (!request.success) {
    logError({
      message: 'Validation failed for onboarding request',
      error: new Error('Validation Error'),
      context: {
        validationErrors: request.error.errors,
        formattedErrors: request.error.format(),
      },
    });
    throw new Error(`Validation failed: ${request.error.message}`);
  }

  const { status, data } = await axiosInstance.post('/account', request.data, {
    validateStatus: (status) => status === 201,
  });

  if (status !== 201) {
    throw new Error('Failed to create customer');
  }

  return data;
};

export const useCreateCustomer = () => {
  const queryClient = useQueryClient();

  return useApiMutation({
    mutationFn: createCustomer,
    validator: CustomerContract,
    options: {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['customer-contract'] }),
    },
  });
};

// PUT: /account - Updates customer related data on TCS side
const updateContract = async ({
  contact,
  invoice,
  organizationNumber,
}: UpdateContractParams): Promise<CustomerContractType> => {
  const { userProfile } = useAccountStore.getState();

  if (!userProfile) {
    throw new Error('User profile not found');
  }

  const contractUpdate = buildContractUpdate(contact, invoice, organizationNumber);

  const parsedUpdate = ContractUpdate.safeParse(contractUpdate);

  if (!parsedUpdate.success) {
    console.error('Validation Errors:', {
      errors: parsedUpdate.error.errors,
      formatted: parsedUpdate.error.format(),
    });
    throw new Error(JSON.stringify(parsedUpdate.error.errors, null, 2));
  }

  const { data } = await axiosInstance.put<CustomerContractType>('/account', parsedUpdate.data);

  return data;
};

export const useUpdateContract = () => {
  const queryClient = useQueryClient();

  return useApiMutation({
    mutationFn: updateContract,
    validator: CustomerContract,
    options: {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['customer-contract'] }),
    },
  });
};

// GET: /account - Provides customer related information coming from RIO
const fetchCustomerContract = async (): Promise<CustomerContractType> => {
  const { data } = await axiosInstance.get<CustomerContractType>('/account');

  return data;
};

export const useCustomerContract = () => {
  const { userProfile } = useAccountStore();

  return useApiQuery({
    queryKey: ['customer-contract'],
    fetcher: fetchCustomerContract,
    validator: CustomerContract,
    queryOptions: {
      enabled: !!userProfile?.account,
    },
  });
};
