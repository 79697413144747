import { z } from 'zod';

export const featureEnum = z.enum(['ACCOUNT_ELIGIBLE', 'ACCOUNT_ALLOWED_TO_FINISH_ONBOARDING']);

const paginationSchema = z.object({
  offset: z.number(),
  totalCount: z.number(),
  limit: z.number(),
  lastPage: z.boolean(),
});

const addressSchema = z.object({
  firstAddressLine: z.string(),
  secondAddressLine: z.string().nullable().optional(),
  thirdAddressLine: z.string().nullable().optional(),
  zip: z.string(),
  city: z.string(),
  countryCode: z.string(),
});

const vatEntrySchema = z.object({
  vatNumber: z.string(),
  countryCode: z.string(),
  permanentEstablishment: z.boolean(),
});

export const AccountDto = z.object({
  permissions: z.array(z.string()),
  name: z.string(),
  vatNumber: z.string(),
  address: addressSchema,
  features: z.array(z.string()),
  fleetMonitorURL: z.string(),
  isOnboarded: z.boolean().optional(),
});

export const CompanyDetails = z.object({
  organizationNumber: z.string().optional(),
  vatList: z.array(vatEntrySchema).optional(),
  invoiceEmailAddress: z.string(),
});

export const ContactDetails = z.object({
  contactPhoneNumber: z.string(),
  contactEmail: z.string(),
});

export const CardCustomization = z.object({
  firstRow: z.string().nullable().optional(),
  secondRow: z.string().nullable().optional(),
  alias: z.string().nullable().optional(),
  productId: z.number(),
  supportLabel: z.string(),
  supportPhone: z.string(),
});

export const CardOrderDetails = z.object({
  letterLanguage: z.string(),
  shippingAddress: addressSchema,
  cardDetails: z.array(CardCustomization),
});

export const OrderRfidCards = z.object({
  letterLanguage: z.string(),
  shippingAddress: addressSchema,
  cardDetails: z.array(CardCustomization),
});

export const OnboardingRequest = z.object({
  companyDetails: CompanyDetails,
  contactDetails: z.object({
    contactPhoneNumber: z.string(),
    contactEmail: z.string(),
  }),
  cardOrderDetails: z.object({
    letterLanguage: z.string(),
    shippingAddress: addressSchema,
    cardDetails: z.array(
      z.object({
        firstRow: z.string().nullable().optional(),
        secondRow: z.string().nullable().optional(),
        alias: z.string().nullable().optional(),
        productId: z.number(),
        supportLabel: z.string(),
        supportPhone: z.string(),
      }),
    ),
  }),
});

export const OnboardingResponse = z.object({
  tariffId: z.string().optional(),
});

export const CustomerContract = z.object({
  companyName: z.string(),
  ...CompanyDetails.shape,
  vatNumber: z.string(),
  ...addressSchema.shape,
  customerId: z.string(),
  entityId: z.number().optional(),
  companyUUID: z.string(),
  contactUUID: z.string(),
  username: z.string(),
  customerSequenceNumber: z.number(),
  contactPhoneNumber: z.string(),
  contactEmail: z.string(),
  currency: z.string().optional(),
  tariffId: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  createdBy: z.string().optional(),
  updatedBy: z.string().optional(),
  OrderId: z.string().optional(),
  contactEInvoicingCodes: z.array(z.string().nullable()).optional(),
});

export const ContractUpdate = z.object({
  vatList: z.array(vatEntrySchema),
  contactPhoneNumber: z.string(),
  contactEmailAddress: z.string().optional(),
  invoiceEmailAddress: z.string(),
  organizationNumber: z.string().optional(),
});

export const ChargingSession = z.object({
  sessionId: z.string(),
  token: z.object({
    number: z.string(),
    serial: z.string(),
    alias: z.string().nullable().optional(),
  }),
  chargingPoint: z.object({
    evseId: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    countryCode: z.string().nullable().optional(),
    plugType: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    zip: z.string().nullable().optional(),
    fleetMonitorUrl: z.string().nullable().optional(),
    location: z
      .object({
        longitude: z.number(),
        latitude: z.number(),
      })
      .optional(),
    operatorName: z.string().nullable().optional(),
  }),
  totalCost: z.number().nullable().optional(),
  currency: z.string().nullable().optional(),
  energy: z.number().nullable().optional(),
  duration: z.string().nullable().optional(),
  startedAt: z.string().nullable().optional(),
  endedAt: z.string().nullable().optional(),
});

export const ChargingSessions = z.object({
  chargingSessions: z.array(ChargingSession),
  pagination: paginationSchema,
});

export const RfidCard = z.object({
  serial: z.string(),
  number: z.string(),
  isActive: z.boolean(),
  alias: z.string().nullable().optional(),
});

export const RfidCardsList = z.object({
  cards: z.array(RfidCard),
});

export const RfidCardUpdate = z.object({
  serial: z.string(),
  alias: z.string().nullable().optional(),
  isActive: z.boolean(),
  hasStatusChanged: z.boolean().optional(),
});

export const QuickFeedbackCreate = z.object({
  rating: z.enum(['GOOD', 'BAD']),
  description: z.string().nullable().optional(),
  origin: z.enum(['ONBOARDING']),
});

export const GeneralFeedbackCreate = z.object({
  rating: z.number(),
  description: z.string().nullable().optional(),
  canContact: z.boolean(),
  contact: z.string().nullable().optional(),
});

export const CardOrdersResponse = z.object({
  orderId: z.string(),
  address: addressSchema,
  cardStatus: z.enum(['SHIPPED', 'RECEIVED', 'PLACED']),
  shippingDate: z.string().optional(),
  cards: z.array(
    z.object({
      firstRow: z.string(),
      secondRow: z.string(),
      productId: z.string(),
      supportLabel: z.string(),
      supportPhone: z.string(),
    }),
  ),
});

export const Invoice = z.object({
  invoiceNumber: z.string().nullable().optional(),
  totalCost: z.number().nullable().optional(),
  currency: z.string().nullable().optional(),
  issueDate: z.string().nullable().optional(),
  dueDate: z.string().nullable().optional(),
  downloadLink: z.string().nullable().optional(),
});

export const Invoices = z.object({
  invoices: z.array(Invoice),
  pagination: paginationSchema,
});

// Type exports
export type AccountType = z.infer<typeof AccountDto>;
export type OnboardingRequestType = z.infer<typeof OnboardingRequest>;
export type CompanyDetailsType = z.infer<typeof CompanyDetails>;
export type ContactDetailsType = z.infer<typeof ContactDetails>;
export type CardOrderDetailsType = z.infer<typeof CardOrderDetails>;
export type ShippingAddressType = z.infer<typeof addressSchema>;
export type CustomerContractType = z.infer<typeof CustomerContract>;
export type ContractUpdateType = z.infer<typeof ContractUpdate>;
export type ChargingSessionsType = z.infer<typeof ChargingSessions>;
export type ChargingSessionType = z.infer<typeof ChargingSession>;
export type RfidCardType = z.infer<typeof RfidCard>;
export type RfidCardsListType = z.infer<typeof RfidCardsList>;
export type RfidCardUpdateType = z.infer<typeof RfidCardUpdate>;
export type OrderRfidCardsType = z.infer<typeof OrderRfidCards>;
export type CardOrdersResponseType = z.infer<typeof CardOrdersResponse>;
export type InvoicesType = z.infer<typeof Invoices>;
export type InvoiceType = z.infer<typeof Invoice>;
export type QuickFeedbackCreateType = z.infer<typeof QuickFeedbackCreate>;
export type GeneralFeedbackCreateType = z.infer<typeof GeneralFeedbackCreate>;
