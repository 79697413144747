import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@rio-cloud/rio-uikit/Button';
import type { SelectOption } from '@rio-cloud/rio-uikit/Select';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';

import Input from '@/components/ui/Input';
import SelectInput from '@/components/ui/SelectInput';

import { useCountryNames } from '@/hooks/useCountryNames';

import { t } from '@/lib/utils';

const ShippingSchema = (intl: IntlShape) => {
  return z
    .object({
      address: z.string().min(1, t('general.atLeastOneLetter', {}, intl)),
      address2: z.optional(z.string()),
      country: z.optional(z.string().min(1)),
      postCode: z.string().min(1, t('general.atLeastOneLetter', {}, intl)),
      city: z.string().min(1, t('general.atLeastOneLetter', {}, intl)),
    })
    .refine((shipping) => shipping?.country !== undefined, {
      message: t('general.selectOneCountry', {}, intl),
      path: ['country'],
    });
};
export type ShippingSchemaType = z.infer<ReturnType<typeof ShippingSchema>>;

export type ShippingAddressStepProps = {
  state?: ShippingSchemaType;
  setState: (state: ShippingSchemaType) => void;
  handleNext: () => void;
  handleBack: () => void;
};

export const ShippingAddressStep = (props: ShippingAddressStepProps) => {
  const { state, setState, handleNext, handleBack } = props;
  const countryNames = useCountryNames;
  const intl = useIntl();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ShippingSchemaType>({
    defaultValues: { ...state },
    shouldFocusError: false,
    resolver: zodResolver(ShippingSchema(intl)),
  });

  const handleInternalNext = (data: ShippingSchemaType) => {
    setState(data);
    handleNext();
  };

  return (
    <div className="display-flex flex-column">
      <div className="border-color-gray mb-5 rounded border bg-white p-10">
        <div className="text-bold text-size-18 pb-5">
          <FormattedMessage id="onboarding.shippingAddress.title" />
        </div>
        <div className="pb-5">
          <FormattedMessage id="onboarding.shippingAddress.description" />
        </div>
        <div className="display-flex flex-column gap-5">
          <div className="flex flex-auto flex-wrap gap-x-[10px]">
            <div className="flex-column flex flex-1">
              <Input
                required
                label={t('general.address', {}, intl)}
                error={errors?.address?.message}
                inputAttributes={{ ...register('address') }}
              />
              <Input
                label={t('general.addressOptional', {}, intl)}
                error={errors?.address2?.message}
                inputAttributes={{ ...register('address2') }}
              />
            </div>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectInput
                  required
                  className="flex-1"
                  data-testid="country-select"
                  error={errors.country?.message}
                  label={t('general.country', {}, intl)}
                  selectAttributes={{
                    onChange: (item?: SelectOption) => onChange(item?.id),
                    options: countryNames(value),
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-auto flex-wrap gap-x-[15px]">
            <Input
              required
              className="flex-1-0"
              label={t('general.postCode', {}, intl)}
              error={errors?.postCode?.message}
              inputAttributes={{ ...register('postCode') }}
            />
            <Input
              required
              className="flex-1-0"
              label={t('general.city', {}, intl)}
              error={errors?.city?.message}
              inputAttributes={{ ...register('city') }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="text-size-12 pb-5 italic">
          <FormattedMessage id="general.fieldsAreRequired" />
        </div>
      </div>
      <div className="btn-toolbar xs:justify-center md:justify-between">
        <Button className="width-120" onClick={handleBack}>
          <span className="rioglyph rioglyph-chevron-left text-bold text-size-14 padding-right-5" />
          <span>{t('onboarding.button.back')}</span>
        </Button>
        <Button className="width-120" onClick={handleSubmit((data) => handleInternalNext(data))} bsStyle="primary">
          <span className="padding-right-5">
            <FormattedMessage id="onboarding.button.next" />
          </span>
          <span className="rioglyph rioglyph-chevron-right text-bold text-size-14" />
        </Button>
      </div>
    </div>
  );
};
