import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Stepper } from '@/components/RfidOrderModal/Stepper';
import { CloseInfo, TrackPagesOnRfidOrderModal } from '@/components/RfidOrderModal/types';
import { trackOrderAbandon, trackOrderEntryPoint } from '@/components/RfidOrderModal/utils/analytics';

import { DialogType, useModal } from '@/hooks/useModal';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const RfidOrderModal = () => {
  const { openDialog, closeDialog } = useModal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isOpen = searchParams.get('modal') === 'orderCards';
  const sourceParam = searchParams.get('modalSource');
  const source: TrackPagesOnRfidOrderModal = isValidModalSource(sourceParam) ? sourceParam : 'direct_link';

  const [currentStepInfo, setCurrentStepInfo] = useState<CloseInfo>({
    currentStep: 0,
    stepName: 'charging_cards',
    orderStatus: 'pending',
  });

  const handleStepUpdate = useCallback((closeInfo: CloseInfo) => {
    setCurrentStepInfo(closeInfo);
  }, []);

  const handleClose = useCallback(() => {
    if (currentStepInfo.orderStatus === 'pending') {
      trackOrderAbandon(currentStepInfo.currentStep, currentStepInfo.stepName, source);
    }
    navigate('?', { replace: true });
  }, [source, currentStepInfo, navigate]);

  useEffect(() => {
    if (isOpen) {
      openDialog({
        type: DialogType.SIMPLE,
        show: true,
        onClose: handleClose,
        title: <FormattedMessage id="chargingCards.newOrder.title" />,
        body: <Stepper setDisplayDialog={handleStepUpdate} source={source} onStepChange={handleStepUpdate} />,
        bsSize: 'lg',
        useOverflow: true,
      });
      trackOrderEntryPoint(source);
    } else {
      closeDialog();
    }
  }, [isOpen, handleStepUpdate, handleClose, openDialog, closeDialog, source]);

  return <></>;
};

const isValidModalSource = (value: string | null): value is TrackPagesOnRfidOrderModal => {
  return value === 'overview_page' || value === 'admin_cards_page';
};
