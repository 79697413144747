import type { ComponentProps } from 'react';

import Select from '@rio-cloud/rio-uikit/Select';

import FormGroup from '@/components/ui/FormGroup';

type SelectInputProps = {
  selectAttributes: ComponentProps<typeof Select>;
} & ComponentProps<typeof FormGroup>;

const SelectInput = ({ label, id = `${label}-select`, selectAttributes, ...formGroupProps }: SelectInputProps) => {
  return (
    <FormGroup {...formGroupProps} label={label}>
      <Select {...selectAttributes} id={id} />
    </FormGroup>
  );
};
export default SelectInput;
