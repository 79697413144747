import { useQueryClient } from '@tanstack/react-query';

import { useAccountStore } from '@/stores/accountStore';

import type { OrderCardsParams } from '@/api/cardOrders/types';
import { buildCardOrderRequest } from '@/api/cardOrders/utils';
import { CardOrdersResponse, OrderRfidCards } from '@/api/schema';

import { useApiMutation } from '@/hooks/useApiMutation';

import { axiosInstance } from '@/lib/api/axios';

// POST: /card-orders
export const orderCards = async (params: OrderCardsParams) => {
  const { userProfile } = useAccountStore.getState();

  if (!userProfile) {
    throw new Error('User profile not found');
  }

  const orderRequest = buildCardOrderRequest(params);

  const parsedRequest = OrderRfidCards.safeParse(orderRequest);

  if (!parsedRequest.success) {
    throw new Error(`Invalid request data: ${parsedRequest.error.message}`);
  }

  const { data } = await axiosInstance.post('/card-orders', parsedRequest.data, {
    validateStatus: (status) => status === 201,
  });

  return data;
};

export const useOrderCards = () => {
  const queryClient = useQueryClient();
  return useApiMutation({
    mutationFn: orderCards,
    validator: CardOrdersResponse,
    options: {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['card-orders'] }),
    },
  });
};
