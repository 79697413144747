import { FormattedMessage } from 'react-intl';

import { Tag } from '@rio-cloud/rio-uikit';

import type { NoServiceFeeAlertProps } from '@/components/NoServiceFeeAlert/types';

export const NoServiceFeeAlert = ({ messageId }: NoServiceFeeAlertProps) => {
  return (
    <div className="alert alert-success my-6">
      <div className="flex gap-10 items-start">
        <Tag className="bg-success text-color-white display-flex" round={false} size="small">
          <FormattedMessage id="alert.NoServiceFee.tag" />
        </Tag>
        <p className="my-0">
          <FormattedMessage id={messageId} />
        </p>
      </div>
    </div>
  );
};
