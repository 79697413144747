import { TrackPagesOnRfidOrderModal } from '@/components/RfidOrderModal/types';
import { createDebugConfig, trackCustomEvent } from '@/configuration/setup/googleAnalytics';

const debugConfig = createDebugConfig();

// Entry points tracking
export const trackOrderEntryPoint = (source: TrackPagesOnRfidOrderModal) => {
  trackCustomEvent(
    'rfid_order_start',
    {
      trigger: 'click',
      flow_name: 'rfid_card_order',
      element_name: 'order_button',
      entry_point: source,
    },
    debugConfig,
  );
};

// Step tracking
export const trackOrderStep = (
  stepNumber: number,
  stepName: string,
  action: 'view' | 'complete' | 'back',
  source: TrackPagesOnRfidOrderModal,
) => {
  trackCustomEvent(
    'rfid_order_step',
    {
      trigger: action === 'view' ? 'visibility' : 'click',
      flow_name: 'rfid_card_order',
      step_number: stepNumber,
      step_name: stepName,
      action: action,
      entry_point: source,
      timestamp: new Date().toISOString(),
    },
    debugConfig,
  );
};

// Order completion tracking
export const trackOrderComplete = (numCards: number, hasCustomization: boolean) => {
  trackCustomEvent(
    'rfid_order_complete',
    {
      trigger: 'click',
      flow_name: 'rfid_card_order',
      cards_ordered: numCards,
      has_customization: hasCustomization,
    },
    debugConfig,
  );
};

// Order error tracking
export const trackOrderError = (errorStep: number, errorType: string) => {
  trackCustomEvent(
    'rfid_order_error',
    {
      trigger: 'system',
      flow_name: 'rfid_card_order',
      error_step: errorStep,
      error_type: errorType,
    },
    debugConfig,
  );
};

// Abandonment tracking
export const trackOrderAbandon = (currentStep: number, stepName: string, source: TrackPagesOnRfidOrderModal) => {
  trackCustomEvent(
    'rfid_order_abandon',
    {
      trigger: 'click',
      flow_name: 'rfid_card_order',
      step_number: currentStep,
      step_name: stepName,
      entry_point: source,
      timestamp: new Date().toISOString(),
    },
    debugConfig,
  );
};
