import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';

import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@rio-cloud/rio-uikit/Button';
import NumberControl from '@rio-cloud/rio-uikit/NumberControl';
import { z } from 'zod';

import { ChargingCardsCustomization } from '@/components/ChargingCardsCustomization';

import { CHARGING_CARDS_CHARACTER_LIMIT, MAX_CARDS_REQUEST_LIMIT, MIN_CARDS_REQUEST_LIMIT } from '@/lib/constants';
import { cn, t } from '@/lib/utils';

import manCard from '/man_card.svg';
import { NoServiceFeeAlert } from '../NoServiceFeeAlert';

export const createRfidChargingCardsSchema = (intl: IntlShape) => {
  const CardsCustomizationSchema = z.object({
    line1: z.string().max(CHARGING_CARDS_CHARACTER_LIMIT, {
      message: t('general.cardCharacterLimit', {}, intl),
    }),
    line2: z.string().max(CHARGING_CARDS_CHARACTER_LIMIT, {
      message: t('general.cardCharacterLimit', {}, intl),
    }),
  });

  return z.object({
    cardsNumber: z.number().min(MIN_CARDS_REQUEST_LIMIT).max(MAX_CARDS_REQUEST_LIMIT),
    cardsListCustomization: z.array(CardsCustomizationSchema),
  });
};

export type RfidChargingCardsSchemaType = z.infer<ReturnType<typeof createRfidChargingCardsSchema>>;

export type ChargingCardsStepProps = {
  state: RfidChargingCardsSchemaType;
  setState: (state: RfidChargingCardsSchemaType) => void;
  handleNext: () => void;
};

export const ChargingCardsStep = ({ state, setState, handleNext }: ChargingCardsStepProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const intl = useIntl();
  const schema = createRfidChargingCardsSchema(intl);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<RfidChargingCardsSchemaType>({
    defaultValues: {
      cardsNumber: state?.cardsNumber || MIN_CARDS_REQUEST_LIMIT,
      cardsListCustomization: state?.cardsListCustomization || [{ line1: '', line2: '' }],
    },
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const cardsNumber = watch('cardsNumber', state?.cardsNumber || MIN_CARDS_REQUEST_LIMIT);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cardsListCustomization',
  });

  const handleCardsUpdate = () => {
    if (cardsNumber > fields.length) {
      append({ line1: '', line2: '' }, { shouldFocus: false });
    } else if (cardsNumber < fields.length) {
      remove(fields.length - 1);
    }
  };

  const handleFormSubmit = (data: RfidChargingCardsSchemaType) => {
    setState(data);
    handleNext();
  };

  const renderCardLimitWarning = () => {
    if (cardsNumber !== MAX_CARDS_REQUEST_LIMIT) {
      return null;
    }

    return (
      <div className="border-color-gray panel bg-lightest flex flex-row gap-5 rounded p-5">
        <span className="text-size-h3 icon rioglyph rioglyph-info-sign text-color-info pl-2" />
        <div className="mt-1 flex flex-col">
          <span className="font-bold">
            <FormattedMessage id="general.cardOrderingLimit.title" />
          </span>
          <span>
            <FormattedMessage id="general.cardOrderingLimit.description" values={{ cardLimit: cardsNumber }} />
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    handleCardsUpdate();
  }, [cardsNumber, fields.length]);

  return (
    <div className="display-flex flex-column">
      <NoServiceFeeAlert messageId="alert.NoServiceFee.description2" />
      <div className="border-color-gray mb-5 rounded border bg-white p-10">
        <div className="display-flex flex-row items-center gap-20 mb-5">
          <div className="flex-basis-80pct text-size-14">
            <h2 className="text-bold text-size-18 pb-5">
              <FormattedMessage id="onboarding.howManyChargingCards.title" />
            </h2>
            <p className="pb-5">
              <FormattedMessage id="onboarding.howManyChargingCards.description" />
            </p>
            <div className="form-group mb-0">
              <label className="control-label">
                <FormattedMessage id="onboarding.numberOfChargingCards" />*
              </label>
              <div className="w-48">
                <Controller
                  name="cardsNumber"
                  control={control}
                  render={({ field }) => (
                    <NumberControl
                      min={MIN_CARDS_REQUEST_LIMIT}
                      max={MAX_CARDS_REQUEST_LIMIT}
                      value={field?.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex-basis-20pct">
            <img className="w-full" width="193" height="121" src={manCard} alt="man_card" />
          </div>
        </div>

        {renderCardLimitWarning()}

        <div className={cn('transition-all duration-500 ease-in-out', isOpen ? '' : 'overflow-hidden')}>
          <ChargingCardsCustomization
            fields={fields}
            control={control}
            errors={errors}
            isOpen={isOpen}
            setOpen={setOpen}
            watch={watch}
          />
        </div>
      </div>

      <div>
        <p className="text-size-12 pb-5 italic">
          <FormattedMessage id="general.fieldsAreRequired" />
        </p>
      </div>

      <div className="btn-toolbar xs:justify-center md:justify-end">
        <Button className="width-120" onClick={handleSubmit(handleFormSubmit)} bsStyle="primary">
          <span className="padding-right-5">
            <FormattedMessage id="onboarding.button.next" />
          </span>
          <span className="rioglyph rioglyph-chevron-right text-bold text-size-14" />
        </Button>
      </div>
    </div>
  );
};
