import type { HTMLAttributes } from 'react';

import { cva } from 'class-variance-authority';

type FormGroupProps = {
  label: string;
  required?: boolean;
  htmlFor?: string;
  error?: string;
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

const formGroup = cva(['form-group', 'min-w-[20rem]', 'last:mb-0'], {
  variants: {
    error: {
      true: ['has-feedback', 'has-error'],
    },
  },
});

const FormGroup = ({
  label,
  required,
  htmlFor = `${label}-input`,
  error,
  className,
  children,
  ...props
}: FormGroupProps) => {
  return (
    <div className={formGroup({ error: !!error, className })} {...props}>
      <label className="control-label" htmlFor={htmlFor}>
        {label}
        {required && <sup>*</sup>}
      </label>
      {children}
      {error ? (
        <>
          <span className="help-block">{error}</span>
          <span className="form-control-feedback rioglyph rioglyph-error-sign" aria-hidden="true" />
        </>
      ) : null}
    </div>
  );
};

export default FormGroup;
