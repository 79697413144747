import { Route, createHashRouter, createRoutesFromElements } from 'react-router-dom';

import { suspendPageLoad } from '@/routes/suspendPageLoad';

import AppLayout from '@/layout/AppLayout';

import { ErrorBoundary } from '@/components/ErrorBoundary';

// Lazy load pages for better performance and automatically split the bundle accordingly
const DashboardPage = suspendPageLoad(() => import('@/pages/dashboard/Dashboard'));
const CardsPage = suspendPageLoad(() => import('@/pages/administration/Cards'));
const ContractPage = suspendPageLoad(() => import('@/pages/administration/Contract'));
const InvoicesPage = suspendPageLoad(() => import('@/pages/invoices'));
const ChargingActivityPage = suspendPageLoad(() => import('@/pages/chargingActivity'));
const SignupPage = suspendPageLoad(() => import('@/pages/signup/Signup'));
const NotFound = suspendPageLoad(() => import('@/pages/NotFound'));
const NoPermissions = suspendPageLoad(() => import('@/pages/NoPermissions'));
const NotBookedState = suspendPageLoad(() => import('@/pages/NotBooked'));

export const DEFAULT_ROUTE = '/dashboard';
export const ADMINISTRATION_CARDS = '/administration/cards';
export const ADMINISTRATION_CONTRACT = '/administration/contract';
export const INVOICES = '/invoices';
export const CHARGING_ACTIVITY = '/charging/activity';
export const SIGNUP = '/signup';
export const NOT_FOUND = '/not-found';
export const NO_PERMISSIONS = '/unauthorized';
export const NOT_AVAILABLE = '/not-available';

export const routes = [
  DEFAULT_ROUTE,
  ADMINISTRATION_CARDS,
  ADMINISTRATION_CONTRACT,
  INVOICES,
  CHARGING_ACTIVITY,
  SIGNUP,
  NOT_FOUND,
  NO_PERMISSIONS,
  NOT_AVAILABLE,
];

export const permissionsMap = new Map<string, string>([
  [DEFAULT_ROUTE, 'emsp-dashboard'],
  [ADMINISTRATION_CARDS, 'emsp-cards.read'],
  [ADMINISTRATION_CONTRACT, 'emsp-contract.read'],
  [INVOICES, 'emsp-invoices.read'],
  [CHARGING_ACTIVITY, 'emsp-charging-sessions.read'],
  [SIGNUP, 'emsp-onboarding'],
]);

export const pageTitles: { page: string; title: string }[] = [
  { page: DEFAULT_ROUTE, title: 'Overview' },
  { page: ADMINISTRATION_CARDS, title: 'Administration Charging Cards' },
  { page: ADMINISTRATION_CONTRACT, title: 'Administration Charging Contract' },
  { page: INVOICES, title: 'Invoices' },
  { page: CHARGING_ACTIVITY, title: 'Charging Activity' },
  { page: SIGNUP, title: 'Sign up' },
];

export const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route path={DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<DashboardPage />} />
      <Route path={ADMINISTRATION_CARDS} errorElement={<ErrorBoundary />} element={<CardsPage />} />
      <Route path={ADMINISTRATION_CONTRACT} errorElement={<ErrorBoundary />} element={<ContractPage />} />
      <Route path={INVOICES} errorElement={<ErrorBoundary />} element={<InvoicesPage />} />
      <Route path={CHARGING_ACTIVITY} errorElement={<ErrorBoundary />} element={<ChargingActivityPage />} />
      <Route path={SIGNUP} errorElement={<ErrorBoundary />} element={<SignupPage />} />
      <Route path={NOT_FOUND} errorElement={<ErrorBoundary />} element={<NotFound />} />
      <Route path={NO_PERMISSIONS} errorElement={<ErrorBoundary />} element={<NoPermissions />} />
      <Route path={NOT_AVAILABLE} errorElement={<ErrorBoundary />} element={<NotBookedState />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);
