import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './Feedback.css';
import { useCreateGeneralFeedback } from '@/api/feedback';
import { GeneralFeedbackCreate, type GeneralFeedbackCreateType } from '@/api/schema';
import InternalErrorState from '@/components/InternalErrorState';
import { createDebugConfig, trackCustomEvent } from '@/configuration/setup/googleAnalytics';
import { useAccountStore } from '@/stores/accountStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
// @ts-ignore
import ReactStars from 'react-rating-stars-component';

// configuration of feedback form
const STARS_COUNT = 5;
const TOTAL_WORD_COUNT = 3000;
const STARS_WIDTH = 60;
const TEXT_AREA_ROWS = 4;

export const FeedbackModal = () => {
  const [canContact, setCanContact] = useState<boolean | undefined>(undefined);
  const [wordCount, setWordCount] = useState<number>(0);
  const debugConfig = createDebugConfig();
  const [showFeedbackReceived, setShowFeedbackReceived] = useState<boolean | undefined>(undefined);
  const createGeneralFeedbackMutation = useCreateGeneralFeedback();
  const { userProfile } = useAccountStore.getState();

  const handleTextChange = (event: { target: { value: string } }) => {
    const value = event.target.value;
    const currentWordCount = value.length;
    setWordCount(currentWordCount);
  };

  const handler = async (values: GeneralFeedbackCreateType) => {
    try {
      await createGeneralFeedbackMutation.mutateAsync({
        canContact: values.canContact,
        description: values.description,
        rating: values.rating,
        contact: canContact ? userProfile?.email : undefined,
      });

      trackCustomEvent(
        'create_general_feedback',
        {
          trigger: 'click',
          flow_name: 'general_feedback',
          rating: values.rating,
          has_feedback_answer: values.description !== null,
          wants_to_be_contacted: values.canContact,
        },
        debugConfig,
      );
      setShowFeedbackReceived(true);
    } catch {
      setShowFeedbackReceived(false);
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { isLoading },
  } = useForm<GeneralFeedbackCreateType>({
    mode: 'all',
    resolver: zodResolver(GeneralFeedbackCreate),
  });

  const showSuccessFeedbackMessage = () => {
    return (
      <div className="border-color-light border rounded display-flex items-center flex-col my-24 mx-auto py-16 max-width-80pct">
        <span className="mb-8 text-9xl">
          <i className="rioglyph rioglyph-ok-sign text-color-primary" />
        </span>
        <h2 className="text-center font-bold text-[#2A3740] mt-0 mb-8">
          <FormattedMessage id="feedbackForm.success.title" />
        </h2>
        <p className="text-center text-color-dark font-medium mx-12 mt-0 mb-8">
          <FormattedMessage id="feedbackForm.success.description" />
        </p>
      </div>
    );
  };

  const showErrorFeedbackMessage = () => {
    return <InternalErrorState reloadTriggerFunction={handleSubmit(handler)} additionalClassName="my-24" />;
  };

  const showFeedbackForm = () => {
    return (
      <>
        <FormattedMessage
          id="feedbackForm.details.appreciate.description1"
          values={{
            b: (chunks) => <b>{chunks}</b>,
          }}
        />
        <br />
        <FormattedMessage id="feedbackForm.details.appreciate.description2" />
        <form id="feedbackForm">
          <ol className="padding-15 list-decimal">
            <li>
              <FormattedMessage
                id="feedbackForm.details.overallSatisfaction.description1"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
              <br />
              <div className="margin-bottom-15">
                <i>
                  <FormattedMessage id="feedbackForm.details.note" />
                </i>
              </div>
              <Controller
                name="rating"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactStars
                    count={STARS_COUNT}
                    onChange={(newRating: number) => {
                      onChange(newRating);
                    }}
                    size={STARS_WIDTH}
                    activeColor="#fccc7c"
                    color="#ffffff"
                  />
                )}
              />
            </li>
            <li className="margin-bottom-10 form-group has-feedback">
              <FormattedMessage
                id={'feedbackForm.details.otherFeedback'}
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />{' '}
              <i>
                (<FormattedMessage id="feedbackForm.details.optional" />)
              </i>
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <textarea
                    className="form-control max-height-200 margin-top-5"
                    value={value?.toString()}
                    rows={TEXT_AREA_ROWS}
                    maxLength={TOTAL_WORD_COUNT}
                    onChange={(newValue) => {
                      onChange(newValue.target.value);
                      handleTextChange(newValue);
                    }}
                  />
                )}
              />
              <span
                className={`help-block display-flex width-100pct justify-content-end ${wordCount === TOTAL_WORD_COUNT ? 'text-color-danger' : ''}`}>
                {wordCount}/{TOTAL_WORD_COUNT}
              </span>
            </li>
            <li className="margin-top-20 margin-bottom-10">
              <FormattedMessage id="feedbackForm.details.contact" />
            </li>
            <Controller
              name="canContact"
              control={control}
              render={({ field: { onChange } }) => (
                <div className="margin-bottom-10">
                  <RadioButton
                    name="ContactRadios"
                    onClick={() => {
                      onChange(true);
                      setCanContact(true);
                    }}
                    inline={true}
                    label={<FormattedMessage id="feedbackForm.details.contactYes" />}
                    defaultChecked={!!canContact}
                  />
                  <RadioButton
                    name="ContactRadios"
                    onClick={() => {
                      onChange(false);
                      setCanContact(false);
                    }}
                    inline={true}
                    label={<FormattedMessage id="feedbackForm.details.contactNo" />}
                    defaultChecked={canContact === false}
                  />
                </div>
              )}
            />
            {!!canContact && (
              <i className="text-size-12">
                <FormattedMessage id="feedbackForm.details.contact.description" />
                {`: ${userProfile?.email}`}
              </i>
            )}
          </ol>
          <button
            className={'btn btn-primary float-right'}
            form="feedbackForm"
            type="submit"
            disabled={watch('rating') === undefined || watch('canContact') === undefined || isLoading}
            onClick={handleSubmit(handler)}>
            <FormattedMessage id="feedbackForm.sendFeedback.button" />
          </button>
        </form>
      </>
    );
  };

  return (
    <div>
      <p className="text-size-h2 text-[#2A3740]">
        <FormattedMessage id="feedbackForm.title" />
      </p>
      {showFeedbackReceived && showSuccessFeedbackMessage()}
      {showFeedbackReceived === false && showErrorFeedbackMessage()}
      {showFeedbackReceived === undefined && showFeedbackForm()}
    </div>
  );
};
