import { FeedbackModal } from '@/components/Feedback/FeedbackModal';
import TooltipRef from '@/components/Tooltip/Tooltip';
import { DialogType, useModal } from '@/hooks/useModal';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const FeedbackButton = () => {
  const intl = useIntl();
  const { openDialog } = useModal();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openModal) {
      openDialog({
        type: DialogType.SIMPLE,
        show: true,
        onClose: () => setOpenModal(false),
        title: <FormattedMessage id="userManual.modalTitle" />,
        body: <FeedbackModal />,
        bsSize: 'md',
        className: 'feedbackDialog',
        useOverflow: true,
      });
    }
  }, [openModal, openDialog]);

  return (
    <ActionBarItem id="feedbackButton">
      <ActionBarItem.Icon data-test-id="general-feedback">
        <TooltipRef
          ref={ref}
          tooltipContent={intl.formatMessage({
            id: 'feedbackForm.icon.message',
          })}
          placement={Tooltip.BOTTOM}>
          <span role="button" className="icon rioglyph rioglyph-comment" onClick={() => setOpenModal(true)} />
        </TooltipRef>
      </ActionBarItem.Icon>
    </ActionBarItem>
  );
};

export default FeedbackButton;
