import { useLocaleStore } from '@/stores/localeStore';

import { getAllowedCountries } from '@/stores/countryStore';

export const useCountryNames = (value?: string, filter?: string[]) => {
  const { displayLocale } = useLocaleStore();
  if (!displayLocale) {
    return undefined;
  }
  const regionNames = new Intl.DisplayNames([displayLocale], {
    type: 'region',
  });

  const countries = getAllowedCountries();
  return countries
    .filter((c) => !filter?.includes(c))
    .map((country) => ({
      id: country,
      label: regionNames.of(country),
      selected: value === country,
    }));
};
