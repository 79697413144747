import { TourProvider, useTour } from '@reactour/tour';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Dialog } from '@rio-cloud/rio-uikit';

import { CustomNavigation } from '@/features/guidedTour/components/CustomNavigation';
import type { TourComponentProps } from '@/features/guidedTour/types';
import {
  trackTourComplete,
  trackTourDecline,
  trackTourDrop,
  trackTourStart,
} from '@/features/guidedTour/utils/analytics';
import { steps } from '@/features/guidedTour/utils/steps';

const RENDER_DELAY = 300;

export const TourComponent = ({ formattedSteps }: TourComponentProps) => {
  const { setIsOpen, setCurrentStep } = useTour();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const isRfidModalOpen = searchParams.get('modal') === 'orderCards';

  const checkTourStatus = useCallback(() => {
    const tourInteraction = localStorage.getItem('tourInteraction');
    const lastStep = localStorage.getItem('lastStep');

    if (isRfidModalOpen) {
      // Don't show the tour if RFID modal is open
      return;
    }

    if (!tourInteraction || (tourInteraction === 'started' && !lastStep)) {
      setShowDialog(true);
    } else if (tourInteraction === 'started' && lastStep) {
      const stepIndex = Number(lastStep);
      if (stepIndex < formattedSteps.length) {
        setCurrentStep(stepIndex);
        navigate(formattedSteps[stepIndex].route);
        setTimeout(() => setIsOpen(true), RENDER_DELAY);
      } else {
        setShowDialog(true);
      }
    }
  }, [setIsOpen, setCurrentStep, isRfidModalOpen]);

  useEffect(() => {
    checkTourStatus();
  }, [checkTourStatus]);

  useEffect(() => {
    // Close the tour if RFID order modal opens while tour is active
    if (isRfidModalOpen) {
      setIsOpen(false);
    }
  }, [isRfidModalOpen, setIsOpen]);

  const startTour = () => {
    setShowDialog(false);
    setCurrentStep(0);
    localStorage.setItem('tourInteraction', 'started');
    localStorage.setItem('lastStep', '0');
    navigate(formattedSteps[0].route);

    const isManualStart = localStorage.getItem('tourPreviouslyCompleted') === 'true';
    trackTourStart(isManualStart ? 'manual' : 'initial');

    setTimeout(() => setIsOpen(true), RENDER_DELAY);
  };

  const skipTour = () => {
    setShowDialog(false);
    localStorage.setItem('tourInteraction', 'skipped');
    localStorage.removeItem('lastStep');
    trackTourDecline('skip');
  };

  const closeModal = () => {
    setShowDialog(false);
    localStorage.setItem('tourInteraction', 'closed');
    trackTourDecline('close');
  };

  return (
    <Dialog
      show={showDialog}
      onClose={closeModal}
      title={<FormattedMessage id="guidedTour.modal.title" />}
      body={
        <div>
          <img className="w-full mb-10" src="/tour.svg" alt="Guided Tour" />
          <FormattedMessage
            id="guidedTour.modal.body"
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </div>
      }
      footer={
        <div className="flex justify-end gap-4 mt-4">
          <Button onClick={skipTour}>
            <FormattedMessage id="guidedTour.modal.skip" />
          </Button>
          <Button onClick={startTour} bsStyle="primary">
            <FormattedMessage id="guidedTour.modal.start" />
          </Button>
        </div>
      }
    />
  );
};

export const GuidedTourModal: React.FC = () => {
  const [tourStartTime] = useState<number>(Date.now());

  const formattedSteps = useMemo(
    () =>
      steps.map((step) => ({
        ...step,
        content: () => (
          <>
            <h5 data-testid="guided-tour-title">
              <FormattedMessage id={`${step.key}.title`} />
            </h5>
            <p data-testid="guided-tour-body">
              <FormattedMessage id={`${step.key}.body`} />
            </p>
          </>
        ),
      })),
    [],
  );

  const handleTourEnd = (currentStep: number) => {
    const timeSpent = Math.floor((Date.now() - tourStartTime) / 1000);

    if (currentStep === formattedSteps.length - 1) {
      trackTourComplete(timeSpent);
      localStorage.setItem('tourPreviouslyCompleted', 'true');
    } else {
      trackTourDrop(currentStep, formattedSteps.length, timeSpent);
    }
  };

  return (
    <TourProvider
      steps={formattedSteps}
      styles={{
        // @ts-ignore
        popover: (base) => ({
          ...base,
          marginTop: '16px',
          borderRadius: '4px',
          '--reactour-accent': 'var(--brand-secondary)',
        }),
        maskWrapper: (base) => ({
          ...base,
          color: 'rgba(42, 55, 64, 0.4)',
        }),
        badge: (base) => ({
          ...base,
          left: '-16px',
          top: '-16px',
          borderRadius: '100%',
          padding: 0,
          width: 30,
          height: 30,
          font: 'inherit',
          paddingTop: '4px',
        }),
        close: (base) => ({
          ...base,
          right: 16,
          left: 'auto',
          top: 16,
          width: 16,
          height: 16,
        }),
      }}
      beforeClose={() => {
        const currentStep = localStorage.getItem('lastStep');
        handleTourEnd(Number(currentStep));
        if (currentStep) {
          localStorage.setItem('lastStep', currentStep);
        }
      }}
      onClickMask={({ setIsOpen, currentStep }) => {
        handleTourEnd(currentStep);
        setIsOpen(false);
        localStorage.setItem('lastStep', currentStep.toString());
      }}
      onClickClose={({ setIsOpen, currentStep }) => {
        handleTourEnd(currentStep);
        setIsOpen(false);
        localStorage.removeItem('lastStep');
        localStorage.setItem('tourInteraction', 'closed');
      }}
      components={{ Navigation: CustomNavigation }}>
      <TourComponent formattedSteps={formattedSteps} />
    </TourProvider>
  );
};
